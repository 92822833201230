import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import image from '../../assets/object.png';
import css from './SectionHero.module.css';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, products } = props;
  const intl = useIntl();
  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes} role="banner">
      <div className={css.container}>
        <div className={css.left}>
          <div>
            <h1 className={css.heroMainTitle}>
              <FormattedMessage id="SectionHero.title" />
            </h1>
            <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHero.subTitle" />
            </h2>
            
            <div className={css.buttonRow}>
              <NamedLink
                name="ChooseTesterPage"
                className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
                aria-label="Learn how it works"
              >
                <FormattedMessage id="SectionHero.HowItWorksButton" />
              </NamedLink>

              <NamedLink
                name="AboutPage"
                className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
                aria-label="About our service"
              >
                <FormattedMessage id="SectionHero.browseButton" />
              </NamedLink>
            </div>
            
            {products?.length ? null : (
              <NamedLink
                name="SearchPage"
                className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
                aria-label="Show all testers"
              >
                {intl.formatMessage({ id: 'SectionHero.SectionHero.showall' })}
              </NamedLink>
            )}
          </div>
        </div>
        <div className={css.right}>
          <img 
            className={css.image} 
            src={image} 
            alt="Loyalty test illustration" 
            loading="eager" 
            fetchpriority="high"
            width="700" 
            height="700" 
          />
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
