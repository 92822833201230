/**
 * Configuration for IP-based geolocation in the application
 * This centralized config allows easy management of which profiles should show user's location
 */

// Central configuration for profiles that should use IP-based location
// Add any profile names (lowercase) or IDs to this list
export const GEOLOCATION_PROFILES = {
  // By name (case-insensitive)
  names: ['Ari_Loyaltytest', 'rockuses'],
  // By ID - useful for listings that might have the same name but different IDs
  ids: ['64fb6375-e423-49be-bd4c-683270492285', '63a2c818-02c0-4aa6-96d4-b84ff646def6']
};

/**
 * Check if a profile should use geolocation based on name or ID
 * @param {string} authorName - The profile name to check
 * @param {string} id - The profile ID to check
 * @returns {boolean} - Whether this profile should use geolocation
 */
export const shouldUseGeolocation = (authorName, id) => {
  // For names, ignore case and trim whitespace
  const nameMatches = authorName && 
    GEOLOCATION_PROFILES.names.some(configName => 
      configName.toLowerCase() === authorName.toLowerCase().trim());
  
  // For IDs, handle both the full UUID and the possibility that the ID is included within another string
  const idMatches = id && 
    GEOLOCATION_PROFILES.ids.some(configId => 
      configId === id || id.includes(configId));
  
  return nameMatches || idMatches;
}; 