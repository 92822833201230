import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, signupModalDispatch } from '../../ducks/Auth.duck';
import { Modal } from '../../components';
import AuthenticationPage from '../../containers/AuthenticationPage/AuthenticationPage';
import css from './Topbar.module.css';

export default function SignupModal({ currentState, data }) {
  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
  };
  const [signupModal, setSignupModal] = React.useState(true);
  
  // Handle modal close
  const handleClose = () => {
    if (data) {
      data(false);
    } else {
      setSignupModal(false);
    }
  };
  
  return (
    <Modal
      isAuth={true}
      className={css.modalStyle}
      id="AuthenticationPageSignUp.tos"
      isOpen={currentState || signupModal}
      onClose={handleClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <AuthenticationPage type="signup" />
    </Modal>
  );
}
