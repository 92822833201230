import { useState, useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook for handling IP geolocation
 * This hook provides user location data based on their IP address
 * If IP geolocation fails, we'll use the Sharetribe stored location
 * 
 * @param {boolean} shouldUseLocation - Whether to activate location detection
 * @returns {Object} - Location state including userLocation, loading status, etc.
 */
const useIpGeolocation = (shouldUseLocation) => {
  const [state, setState] = useState({
    isLoading: false,
    userLocation: null,
    usingUserLocation: false,
    error: null
  });
  
  const hasRun = useRef(false);
  
  // Fetch location information from server proxy endpoint
  const fetchLocation = useCallback(() => {
    setState(prev => ({ ...prev, isLoading: true }));
    
    // Determine the correct API URL based on environment
    // In development, the API server runs on port 3500
    const apiBaseUrl = process.env.NODE_ENV === 'development' 
      ? 'http://localhost:3500'
      : '';
    
    // Use a server proxy endpoint instead of calling geolocation APIs directly
    fetch(`${apiBaseUrl}/api/geolocation`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      // Include credentials to handle cookies properly across domains in development
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text().then(text => {
          try {
            // Try to parse the response text as JSON
            const data = text.trim() ? JSON.parse(text) : {};
            return data;
          } catch (error) {
            throw new Error('Invalid JSON response: ' + error.message);
          }
        });
      })
      .then(data => {
        // Check if we received valid data
        if (!data || ((!data.city && !data.region) && !data.country_name)) {
          throw new Error('Invalid location data');
        }
        
        // Create a specific address from the IP data
        let locationAddress = '';
        if (data.city) {
          locationAddress = data.city;
          if (data.region) {
            locationAddress += `, ${data.region}`;
          }
        } else if (data.region) {
          locationAddress = `${data.region}, ${data.country_name}`;
        } else {
          locationAddress = data.country_name || 'Unknown location';
        }
        
        setState({
          isLoading: false,
          userLocation: {
            address: locationAddress,
            coords: {
              latitude: parseFloat(data.latitude || '0') || 0,
              longitude: parseFloat(data.longitude || '0') || 0
            }
          },
          usingUserLocation: true,
          error: null
        });
      })
      .catch(error => {
        // If IP geolocation fails, set usingUserLocation to false
        // This will make the app fall back to the Sharetribe stored location
        setState({
          isLoading: false,
          userLocation: null,
          usingUserLocation: false,
          error: error
        });
      });
  }, []);
  
  // Effect to fetch location data
  useEffect(() => {
    if (!shouldUseLocation || hasRun.current) return;
    
    // Mark as run to prevent re-running
    hasRun.current = true;
    setState(prev => ({ ...prev, isLoading: true }));
    
    // Get location information
    fetchLocation();
    
  }, [shouldUseLocation, fetchLocation]);
  
  return state;
};

export default useIpGeolocation; 