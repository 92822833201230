import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';

import {
  Button,
  Form,
} from '../../components';
import { BsInstagram } from 'react-icons/bs';
import css from './EditListingSocialsForm.module.css';

const EditListingSocialsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        instagramErr,
        currentUser,
        invalid,
        intl,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSocialsForm.updateFailed" />
        </p>
      ) : null;
      const instaErrorMessage = instagramErr ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSocialsForm.EditListingSocialsForm.instaConnectError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSocialsForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {instaErrorMessage}
          {errorMessageShowListing}
          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingSocialsForm.EditListingSocialsForm.socialsLabel" />
            </h2>
            <label className={css.subLabel}>
              <FormattedMessage id="EditListingSocialsForm.EditListingSocialsForm.socialsSubLabel" />
            </label>
            <div className={css.socials}>
              <div className={css.social}>
                <a href="https://instagram.com/loyalty_test_clips" target="_blank" rel="noopener noreferrer" className={css.socailButton}>
                  <BsInstagram className={css.icon} /> @loyalty_test_clips
                </a>
              </div>
            </div>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingSocialsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingSocialsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingSocialsForm = EditListingSocialsFormComponent;

export default injectIntl(EditListingSocialsForm);
