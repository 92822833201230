import React from 'react';
import { string, shape, number, object } from 'prop-types';
import polyline from '@mapbox/polyline';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';
import { circlePolyline } from '../../../util/maps';
import config from '../../../config';

const formatColor = color => {
  return color.replace(/^#/, '');
};

export const fuzzyCircleOverlay = (center, mapsConfig) => {
  const strokeWeight = 1;
  const strokeColor = mapsConfig.fuzzy.circleColor;
  const strokeOpacity = 0.5;
  const fillColor = mapsConfig.fuzzy.circleColor;
  const fillOpacity = 0.5;

  const path = circlePolyline(center, mapsConfig.fuzzy.offset);
  const styles = `-${strokeWeight}+${formatColor(strokeColor)}-${strokeOpacity}+${formatColor(
    fillColor
  )}-${fillOpacity}`;
  return `path${styles}(${encodeURIComponent(polyline.encode(path))})`;
};

const customMarkerOverlay = (center, mapsConfig) => {
  const { url } = mapsConfig.customMarker;
  return `url-${encodeURIComponent(url)}(${center.lng},${center.lat})`;
};

const markerOverlay = center => {
  return `pin-s(${center.lng},${center.lat})`;
};

const mapOverlay = (center, mapsConfig) => {
  if (mapsConfig.fuzzy.enabled) {
    return fuzzyCircleOverlay(center, mapsConfig);
  }
  if (mapsConfig.customMarker.enabled) {
    return customMarkerOverlay(center, mapsConfig);
  }
  return markerOverlay(center);
};

// Helper to check if geolocation is valid
const isValidCenter = center => {
  return (
    center && 
    typeof center.lat === 'number' && 
    typeof center.lng === 'number' && 
    !isNaN(center.lat) && 
    !isNaN(center.lng) &&
    // Crude validation for reasonable lat/lng values
    center.lat >= -90 && center.lat <= 90 &&
    center.lng >= -180 && center.lng <= 180
  );
};

const CardMap = props => {
  const { address, center, zoom, mapsConfig, dimensions } = props;
  const { width, height } = dimensions;

  const libLoaded = typeof window !== 'undefined' && window.mapboxgl;
  if (!libLoaded) {
    return null;
  }

  // Check if center is valid
  if (!isValidCenter(center)) {
    return (
      <div 
        style={{ 
          width: '100%', 
          height: '100%', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          backgroundColor: '#f0f0f0',
          borderRadius: '8px'
        }}
      >
        <p style={{ color: '#999', fontSize: '12px' }}>Map unavailable</p>
      </div>
    );
  }

  const overlay = mapOverlay(center, mapsConfig);
  const src =
    'https://api.mapbox.com/styles/v1/mapbox/streets-v10/static' +
    (overlay ? `/${overlay}` : '') +
    `/${center.lng},${center.lat},${zoom}` +
    `/${width}x${height}` +
    `?access_token=${config.maps.mapboxAccessToken}`;

  return <img src={src} alt={address} />;
};

CardMap.defaultProps = {
  address: '',
  center: null,
  zoom: config.maps.fuzzy.enabled ? config.maps.fuzzy.defaultZoomLevel : 11,
  mapsConfig: config.maps,
};

CardMap.propTypes = {
  address: string,
  center: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }),
  zoom: number,
  mapsConfig: object,

  // from withDimensions
  dimensions: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default lazyLoadWithDimensions(CardMap, { maxWidth: '640px' });
