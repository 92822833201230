import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTikTok,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteTikTokPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFB = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToTT = intl.formatMessage({ id: 'Footer.goToTikTok' });
  const goToIG = intl.formatMessage({ id: 'Footer.goToInstagram' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href="https://www.facebook.com/people/Loyalty-Testcom/100093044125087/" className={css.icon} title={goToFB}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instaLink = siteInstagramPage ? (
    <ExternalLink key="linkToInsta" href="https://www.instagram.com/loyalty_test_clips" className={css.icon} title={goToIG}>
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const tiktokLink = siteTikTokPage ? (
    <ExternalLink key="linkToTikTok" href="https://www.instagram.com/loyalty_test_clips" className={css.icon} title={goToTT}>
      <IconSocialMediaTikTok />
    </ExternalLink>
  ) : null;

  return [fbLink, instaLink, tiktokLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <Logo format="desktop" />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="FaqPage" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink href={'mailto:contact@loyalty-test.com'} className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="BlogListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toBlogListingPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            
            <div className={css.searches}>
  <ul className={css.list}>
    <li className={css.listItem}>
      <span className={css.link}>New York</span>
    </li>
    <li className={css.listItem}>
      <span className={css.link}>Los Angeles</span>
    </li>
    <li className={css.listItem}>
      <span className={css.link}>San Francisco</span>
    </li>
    <li className={css.listItem}>
      <span className={css.link}>Seattle</span>
    </li>
    <li className={css.listItem}>
      <span className={css.link}>Portland</span>
    </li>
  </ul>
</div>

            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink
                      // name="LandingPage"
                      name="TermsOfServicePage"
                      className={css.legalLink}
                    >
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink
                      name="PrivacyPolicyPage"
                      // name="LandingPage"
                      className={css.legalLink}
                    >
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink
                      // name="LandingPage"
                      name="TermsOfServicePage"
                      className={css.legalLink}
                    >
                      <FormattedMessage id="Footer.refund" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
