import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ReviewBox.module.css';

const ReviewBox = props => {
  const { rootClassName, className, reviews } = props;
  const scrollContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const classes = classNames(rootClassName || css.root, className);

  // Sample reviews if none provided
  const reviewsToShow = reviews || [
    {
      id: '1',
      content: 'She did really amazing! She reached out to my boyfriend and he told her that he had a girlfriend. He then reached out to her about a week and a half or two weeks later (After my time with her had ended ) Trying to engage with her again. She emailed me to let me know and kept the conversation going. She was extremely helpful, I highly recommend her.',
      rating: 5,
      date: new Date('2023-01-15'),
      testerId: '65df5530-0d7f-45b9-a039-0a27b2ba49e0',
      testerName: 'go-getter9289',
    },
    {
      id: '2',
      content: 'Ari helped me through a pretty rough patch, and im forever grateful. My man passed with flying colours',
      rating: 5,
      date: new Date('2023-02-20'),
      testerId: '64fb6375-e423-49be-bd4c-683270492285',
      testerName: 'ari-loyaltytest',
    },
    {
      id: '3',
      content: "Pikabett replied quickly and was very professional. She came up with a perfect dialog with some information and guidance on my part. Ideal for when you are so worried you can't think straight! Totally worth every penny!",
      rating: 5,
      date: new Date('2023-03-10'),
      testerId: '63fd1ba5-5d67-497e-a45b-78980fc0a9f2',
      testerName: 'pikabett',
    },
    {
      id: '4',
      content: "She was unfortunately successful.. But it saved me from a lifetime of misery.",
      rating: 5,
      date: new Date('2023-04-05'),
      testerId: '65fa5ff6-ab3c-49b1-9f2f-7d709d236cf9',
      testerName: 'laribaby',
    },
    {
      id: '5',
      content: 'Quick response time and professional communication throughout the process. Highly recommended for anyone with concerns.',
      rating: 4,
      date: new Date('2023-05-12'),
      testerId: '6411fb89-3ae6-49c7-9d61-f4193610fd56',
      testerName: 'vylettee',
    },
    {
      id: '6',
      content: 'The tester went above and beyond what was expected. Helped me get closure in my relationship.',
      rating: 5,
      date: new Date('2023-06-18'),
      testerId: '6534a04f-e77b-42f7-b213-a87a7a6cbf5b',
      testerName: 'reinaaxo',
    },
    {
      id: '7',
      content: 'Not all heros wear capes but Keith should, he saved my life, he gave peace to my mind, He gave me peace and he helped me at my lowest point. He is brilliant at what he does, he managed to find out that my partner of 6 years was open to meeting for drinks was a flirt and who knows where it would have led. He changed the course of my life. And i owe him more than he can know.',
      rating: 5,
      date: new Date('2023-06-18'),
      testerId: '64c17a2d-0805-481e-9fc3-b685c36b8757',
      testerName: 'thecaptain',
    },
  ];

  // Auto-rotation effect
  useEffect(() => {
    let interval;
    
    if (!isPaused) {
      interval = setInterval(() => {
        if (scrollContainerRef.current) {
          const cardWidth = 300; // Approximate width of a card including margin
          const nextIndex = (currentIndex + 1) % reviewsToShow.length;
          
          scrollContainerRef.current.scrollTo({
            left: nextIndex * cardWidth,
            behavior: 'smooth'
          });
          
          setCurrentIndex(nextIndex);
        }
      }, 5000); // Rotate every 5 seconds
    }
    
    return () => clearInterval(interval);
  }, [currentIndex, isPaused, reviewsToShow.length]);

  // Handle manual scroll
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const cardWidth = 300; // Approximate width of a card including margin
      const nextIndex = direction === 'left' 
        ? (currentIndex - 1 + reviewsToShow.length) % reviewsToShow.length
        : (currentIndex + 1) % reviewsToShow.length;
      
      scrollContainerRef.current.scrollTo({
        left: nextIndex * cardWidth,
        behavior: 'smooth'
      });
      
      setCurrentIndex(nextIndex);
    }
  };

  // Pause auto-rotation when hovering over the reviews
  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} className={i < rating ? css.starFilled : css.starEmpty}>
          ★
        </span>
      );
    }
    return <div className={css.stars}>{stars}</div>;
  };

  const formatDate = (date) => {
    // Always use current date instead of the review's actual date
    const currentDate = new Date();
    return currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
    });
  };

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="ReviewBox.title" defaultMessage="Tester Reviews" />
      </h2>
      
      <div className={css.scrollContainer}>
        <button 
          className={classNames(css.scrollButton, css.scrollButtonLeft)}
          onClick={() => handleScroll('left')}
          aria-label="Scroll left"
        >
          ‹
        </button>
        
        <div 
          className={css.reviewsWrapper}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={css.gradientLeft} />
          <div className={css.reviewsContainer} ref={scrollContainerRef}>
            {reviewsToShow.map(review => (
              <div key={review.id} className={css.reviewCardWrapper}>
                <div className={css.reviewCard}>
                  <div className={css.reviewContent}>
                    <p className={css.reviewAuthorTop}>Anonymous:</p>
                    <p className={css.reviewText}>{review.content}</p>
                    {renderStars(review.rating)}
                    <div className={css.reviewFooter}>
                      <p className={css.reviewDate}>{formatDate(review.date)}</p>
                      <p className={css.reviewAuthor}>Review For: {review.testerName}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={css.gradientRight} />
        </div>
        
        <button 
          className={classNames(css.scrollButton, css.scrollButtonRight)}
          onClick={() => handleScroll('right')}
          aria-label="Scroll right"
        >
          ›
        </button>
      </div>
      
      <div className={css.indicators}>
        {reviewsToShow.map((_, index) => (
          <button
            key={index}
            className={classNames(css.indicator, { [css.indicatorActive]: index === currentIndex })}
            onClick={() => {
              if (scrollContainerRef.current) {
                const cardWidth = 300; // Approximate width of a card including margin
                scrollContainerRef.current.scrollTo({
                  left: index * cardWidth,
                  behavior: 'smooth'
                });
                setCurrentIndex(index);
              }
            }}
            aria-label={`Go to review ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

ReviewBox.defaultProps = {
  rootClassName: null,
  className: null,
  reviews: null,
};

ReviewBox.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
      date: PropTypes.instanceOf(Date).isRequired,
      testerId: PropTypes.string,
      testerName: PropTypes.string,
    })
  ),
};

export default ReviewBox; 